// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-selbu-whist-instructions-en-js": () => import("./../../../src/pages/selbu-whist-instructions-en.js" /* webpackChunkName: "component---src-pages-selbu-whist-instructions-en-js" */),
  "component---src-pages-selbu-whist-privacy-policy-en-js": () => import("./../../../src/pages/selbu-whist-privacy-policy-en.js" /* webpackChunkName: "component---src-pages-selbu-whist-privacy-policy-en-js" */)
}

